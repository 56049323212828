
import { defineComponent } from 'vue';

const Loading = defineComponent({
    props: {
        color: {
            type: String,
            default: 'white'
        }
    }
});

export default Loading;
