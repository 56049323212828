
import { computed, defineComponent } from 'vue';
import { useAlert } from '@/plugins/alert';
import vm from '@/viewModels/MainViewModel';

//  Components
import Header from '@/components/Header.vue';

import UserDTO from '@/DTOs/UserDTO';
import { useRouter } from 'vue-router';

const AdminMainPage = defineComponent({
    components: { Header },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
        const alert = useAlert();
        const { replace } = useRouter();

        
        /** Logout */
        const logout = () => {
            alert({
                icon: 'logout',
                title: 'Logout',
                message: 'Tem certeza que deseja sair do sistema?',
                actions: [
                    {
                        title: 'Cancelar',
                        primary: true
                    },
                    {
                        title: 'Sair',
                        action: () => {
                            replace({ name: 'login' });
                            vm.logout();
                        }
                    }
                ]
            });
        };

        return { logout, user };
    }
});

export default AdminMainPage;
