<template>
    <div class="flex-1 flex flex-col items-stretch overflow-hidden">
        <video class="h-60 2xl:h-96 bg-black w-full" controls :src="urlVideo" ref="video"></video>
        <div class="px-8 flex-1 overflow-auto">
            <table class="w-full">
                <tr class="h-12">
                    <th class="w-36 text-left font-medium">TIPO</th>
                    <th class="text-left font-medium">DESCRIÇÃO</th>
                    <th class="w-32 font-medium">TEMPO INICIAL</th>
                    <th class="w-32 font-medium">TEMPO FINAL</th>
                    <th class="w-22 font-medium">STATUS</th>
                    <th class="w-22 font-medium">AUDITORIA</th>
                </tr>
                <tr class="h-22 border-t border-neutral-2 cursor-pointer hover:bg-gray-50 text-gray-800"
                    :class="[
                        {'text-green-600': alerta.situacaoAlerta == 'AlertaLiberado'},
                        {'text-red-600': alerta.situacaoAlerta == 'IndicioFraude'},
                        {'text-yellow-600': alerta.situacaoAlerta == 'AlertaInvalido'},
                    ]"
                    @click="goToVideoPosition(alerta.inicio)"
                    v-for="alerta in alertasOrdenados" :key="alerta.id">
                    <td class="font-medium" style="color: inherit">{{ alerta.tipo }}</td>
                    <td style="color: inherit">{{ alerta.descricao }}</td>
                    <td class="text-center" style="color: inherit">{{ formatTime(alerta.inicio)}}</td>
                    <td class="text-center" style="color: inherit">{{ formatTime(alerta.fim) }}</td>
                    <td>
                        <div class="flex justify-center">
                            <img :src="require(`@/assets/icons/${statusIcon(alerta.situacaoAlerta)}.svg`)" class="w-5"/>
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-center">
                            <Dropdown>
                                <div class="p-3">
                                    <img src="@/assets/icons/menu.svg" class="w-5"/>
                                </div>
                                <template #content>
                                    <div class="w-48">
                                        <ul class="py-2">
                                            <li class="font-medium py-2 px-4 text-sm  flex items-center"
                                                @click="auditar('Default', alerta)">
                                                <p class="flex-1 text-gray-400">NÃO AUDITADO</p>
                                                <Icon color="#059669" icon="cross-eye" size="20px" class="mb-1"/>
                                            </li>
                                            <li class="font-medium py-2 px-4 text-sm flex items-center"
                                                @click="auditar('AlertaLiberado', alerta)">
                                                <p class="flex-1  text-green-600">LIBERAR ALERTA</p>
                                                <Icon color="#059669" icon="check-circle" size="20px" class="mb-1"/>
                                            </li>
                                            <li class="font-medium py-2 px-4 text-sm  flex items-center"
                                                @click="auditar('IndicioFraude', alerta)">
                                                <p class="flex-1 text-red-600">INDÍCIO DE FRAUDE</p>
                                                <Icon color="red" icon="times-circle" size="20px" class="mb-1"/>
                                            </li>
                                            <li class="font-medium py-2 px-4 text-sm  flex items-center"
                                                @click="auditar('AlertaInvalido', alerta)">
                                                <p class="flex-1 text-yellow-600">ALERTA INVÁLIDO</p>
                                                <Icon color="#D97706" icon="alert" size="20px" class="mb-1"/>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, PropType, ref } from 'vue';
import { AlertaDTO } from '@/DTOs/AlertaDTO';
import { format } from 'date-fns';
import Dropdown from '@/components/Dropdown.vue';
import Icon from '@/components/Icon.vue';
import AuditoriaDTO from '@/DTOs/AuditoriaDTO';
import vm from '@/viewModels/MainViewModel';
import ExameService from '@/services/ExameService';

const Video = defineComponent({
    components: { Dropdown, Icon },
    props: {
        urlVideo: {
            type: String,
            required: true
        },
        alertas: {
            type: Array as PropType<AlertaDTO[]>,
            required: true
        },
        agendamentoId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const video = ref<HTMLVideoElement | null>(null);
        const alertasOrdenados = [...props.alertas].sort((field1, field2) => {
            if (field1.inicio! < field2.inicio!) {
                return -1;
            }
            if (field1.inicio! > field2.inicio!) {
                return 1;
            }
            // a deve ser igual a b
            return 0;
        });
        const switchOn = ref(false);

        const formatTime = (miliseconds: number) => {
            return format(new Date(0,0,0,0,0,0,miliseconds), "HH:mm:ss");
        };

        const goToVideoPosition = (miliseconds: number) => {
            if(video.value) {
                video.value.currentTime = miliseconds / 1000;
                video.value.play();
            }
        };

        const auditar = (situacao: "AlertaLiberado" | "IndicioFraude" | "AlertaInvalido" | 'Default', alerta: AlertaDTO) => {
            const auditoria: AuditoriaDTO = {
                gravacaoId: props.agendamentoId,
                auditorId: vm.user!.id,
                situacaoAlerta: situacao,
                alertaId: alerta.id,
                tipoAlerta: alerta.tipo
            };

            const oldValue = alerta.situacaoAlerta;
            alerta.situacaoAlerta = situacao;

            const [request] = ExameService.Auditar(auditoria);
            request.catch(() => {
                alerta.situacaoAlerta = oldValue;
            });
        };

        const statusIcon = (situacao: "AlertaLiberado" | "IndicioFraude" | "AlertaInvalido" | 'Default') => {
            switch (situacao) {
                case 'AlertaLiberado':
                    return 'check-circle';
                case 'IndicioFraude':
                    return 'times-circle';
                case 'AlertaInvalido':
                    return 'alert';
                default:
                    return 'cross-eye';
            }
        };

        return { alertasOrdenados, switchOn, formatTime, video, goToVideoPosition, auditar, statusIcon };
    }
});

export default Video;
</script>

<style>

</style>