<template>
    <div class="h-screen">
        <!-- Header -->
        <Header>
            <p>{{ user && user.nome }}</p>
            <div class="bg-center bg-cover bg-no-repeat w-8 h-8 rounded-full bg-neutral-2 mx-6" style="background-image: url(https://goodshepherdirvine.com/wp-content/uploads/2017/08/user-placeholder.jpg)"></div>
            <div class="h-6 w-px bg-neutral-2"/>
            <button class="h-14 px-8" @click="() => logout()">
                <img src="@/assets/icons/logout.svg" class="w-4"/>
            </button>
        </Header>

        <RouterView #default="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </RouterView>

    </div>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';
import { useAlert } from '@/plugins/alert';
import vm from '@/viewModels/MainViewModel';

//  Components
import Header from '@/components/Header.vue';

import UserDTO from '@/DTOs/UserDTO';
import { useRouter } from 'vue-router';

const AdminMainPage = defineComponent({
    components: { Header },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
        const alert = useAlert();
        const { replace } = useRouter();

        
        /** Logout */
        const logout = () => {
            alert({
                icon: 'logout',
                title: 'Logout',
                message: 'Tem certeza que deseja sair do sistema?',
                actions: [
                    {
                        title: 'Cancelar',
                        primary: true
                    },
                    {
                        title: 'Sair',
                        action: () => {
                            replace({ name: 'login' });
                            vm.logout();
                        }
                    }
                ]
            });
        };

        return { logout, user };
    }
});

export default AdminMainPage;
</script>

<style>

</style>