<template>
    <div class="p-3 rounded-lg shadow-1 flex items-start" style="background-color: #EFEFEF">
        <img class="h-25 w-25 rounded-lg object-cover" :src="'https://admin.easyproctor.tech/teorico/exame/api/imagens/'+questao.registroFotoUsuario"/>
        <div class="flex-1 px-5">
            <p class="pb-3 font-medium">Capturada às {{ formattedTime }}</p>
            <p class="text-sm">Não há observações nesta captura</p>
        </div>
        <!-- <img src="@/assets/icons/edit.svg" class="p-1"/> -->
    </div>
</template>

<script lang='ts'>
import RespostaQuestaoDTO from '@/DTOs/RespostaQuestaoDTO';
import { format, parse } from 'date-fns';
import { computed, defineComponent, PropType } from 'vue';

const ImageCard = defineComponent({
    props: {
        questao: {
            type: Object as PropType<RespostaQuestaoDTO>,
            required: true
        }
    },
    setup(props) {
        const formattedTime = computed(() => {
            const date = parse(props.questao.dataHora, "dd/MM/yyyy HH:mm", new Date());
            return format(date, "HH'h'mm");
        });

        return { formattedTime };
    }
});

export default ImageCard;
</script>

<style>

</style>