import { reactive, ref, watch } from "vue";
import UserDTO from '@/DTOs/UserDTO';
import VLibras from '@/components/VLibras.vue';

interface MainViewModelState {
    user: UserDTO | null;
    selectedSchedule: number;
}

class MainViewModel {
    // Referencia ao VLibras
    public vlibras = ref<typeof VLibras | null>(null);

    private state = reactive<MainViewModelState>({
        user: null,
        selectedSchedule: -1
    });

    constructor() {
        // Carregar formulário armazenado
        const userString = localStorage.getItem('EP_User');
        const userBackup = userString ? JSON.parse(userString) as UserDTO : null;
        if(userBackup) {
            this.state.user = userBackup;
        }

        watch(() => this.state.user, () => {
            localStorage.setItem('EP_User', JSON.stringify(this.state.user));    
        });
    }

    // Usuário
    public get user() {
        return this.state.user;
    }
    public set user(value: UserDTO | null) {
        this.state.user = value;
    }

    // Agendamento Selecionado
    public get selectedSchedule() {
        return this.state.selectedSchedule;
    }
    public set selectedSchedule(value: number) {
        this.state.selectedSchedule = value;
    }

    public logout() {
        this.state.user = null;
    }


}

export default new MainViewModel();