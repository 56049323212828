import { createRouter, createWebHistory } from 'vue-router';
import { useMediaPermissions } from '@/plugins/mediaPermission';
import vm from '@/viewModels/MainViewModel';
// ===================== DOWNLOAD ==========================
const DownloadMainView = () => import(/* webpackChunkName: "download" */ '@/views/Download/DownloadMainView.vue');
const DownloadOnboardingView = () => import(/* webpackChunkName: "download" */ '@/views/Download/DownloadOnboardingView.vue');
const DownloadView = () => import(/* webpackChunkName: "download" */ '@/views/Download/DownloadView.vue');

// ===================== LOGIN ==========================
const LoginMainPage = () => import(/* webpackChunkName: "login" */  '@/views/Login/LoginMainPage.vue');
const LoginFormView = () => import(/* webpackChunkName: "login" */ '@/views/Login/LoginFormView.vue');
const RegisterPage = () => import(/* webpackChunkName: "login" */ '@/views/Login/RegisterPage.vue');
const TakePictureView = () => import(/* webpackChunkName: "login" */ '@/views/Login/TakePictureView.vue');

// ===================== CANDIDATO ==========================
const CandidatoMainPage = () => import(/* webpackChunkName: "candidato" */ '@/views/Candidato/CandidatoMainPage.vue') ;
const AgendaPage = () => import(/* webpackChunkName: "candidato" */ '@/views/Candidato/AgendaPage.vue');
const InformacoesPage = () => import(/* webpackChunkName: "candidato" */ '@/views/Candidato/InformacoesPage.vue');
const ExameFinalizadoPage = () => import(/* webpackChunkName: "candidato" */ '@/views/Candidato/ExameFinalizadoPage.vue');
const ExamBiometryPage = () => import(/* webpackChunkName: "candidato" */ '@/views/Candidato/ExamBiometryPage.vue');
const ExamePage = () => import(/* webpackChunkName: "candidato" */ '@/views/Exame/ExamePage.vue');

// ===================== ADMIN ==========================
import AdminMainPage from '@/views/Admin/AdminMainPage.vue';
import MenuPage from '@/views/Admin/MenuPage.vue';
import ExamesPage from '@/views/Admin/Exames/ExamesPage.vue';
import ExecucaoPage from '@/views/Admin/Execucao/ExecucaoPage.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        // ===================== DOWNLOAD ==========================
        {
            path: '/download',
            component: DownloadMainView,
            children: [
                {
                    path: '',
                    name: 'download',
                    component: DownloadView
                },
                {
                    path: 'onboarding',
                    name: 'download-onboarding',
                    component: DownloadOnboardingView
                },
            ]
        },
        // ===================== LOGIN ==========================
        {
            path: '/',
            component: LoginMainPage,
            children: [
                {
                    path: '',
                    name: 'home',
                    redirect: { name: 'download' }
                },
                {
                    path: 'login',
                    name: 'login',
                    component: LoginFormView
                },
                {
                    path: 'login-exame',
                    name: 'login-candidato',
                    component: LoginFormView
                },
                {
                    path: 'register',
                    name: 'register',
                    component: RegisterPage
                },
            ]
        },
        {
            path: '/picture',
            name: 'picture',
            beforeEnter: (to, from, next) => from.name != 'register' ? next({ name: 'register' }) : next(),
            component: TakePictureView
        },
        // ===================== CANDIDATO ==========================
        {
            path: '/candidato',
            component: CandidatoMainPage,
            children: [
                {
                    path: '',
                    name: 'agenda',
                    component: AgendaPage,
                },
                {
                    path: 'informacoes',
                    name: 'informacoes',
                    component: InformacoesPage
                },
                {
                    path: 'finalizacao',
                    name: 'finalizacao',
                    component: ExameFinalizadoPage
                }
            ]
        },
        // ============ CAPTURA BIOMETRIA CANDIDATO =================
        {
            path: '/biometria',
            name: 'biometry',
            meta: { checkMediaPermissions: true },
            component: ExamBiometryPage
        },
        // ============ EXAME =================
        {
            path: '/exame/:id',
            name: 'exame',
            meta: { checkMediaPermissions: true },
            component: ExamePage
        },

        // ============ ADMIN =================
        {
            path: '/admin',
            component: AdminMainPage,
            children: [
                {
                    path: '',
                    name: 'admin',
                    component: MenuPage
                },
                {
                    path: 'exames',
                    name: 'exames',
                    component: ExamesPage
                },
                {
                    path: 'execucao',
                    name: 'execucao',
                    component: ExecucaoPage
                }
            ]
        },
    ]
});

router.beforeEach(async (to) => {
    if(to.meta.checkMediaPermissions) {
        const checkPermissions = useMediaPermissions();
        const granted = await checkPermissions();
        if(!granted) {
            return false;
        }
    }
    if(vm.vlibras.value) {
        setTimeout(() => {
            vm.vlibras.value!.refresh();
        }, 500);
    }
});

export default router;
