<template>
    <div class="h-screen pt-14 flex items-stretch">

        <!-- Listagem -->
        <div style="width: 415px" class="bg-neutral-3">
            <div class="w-full h-full overflow-hidden flex flex-col items-stretch">

                <!-- Header -->
                <div class="relative h-18 w-full bg-white shadow-md px-7 flex items-center">
                    <img src="@/assets/icons/calendar.svg"/>
                    <div class="flex-1 pl-7">
                        <p class="text-sm leading-5">Provas agendadas</p>
                        <p class="font-bold text-lg leading-5">25 / agosto / 2020</p>
                    </div>
                    <button>
                        <img src="@/assets/icons/search.svg"/>
                    </button>
                </div>

                <Transition name="fade" mode="out-in">
                    <div class="flex-1 overflow-x-hidden light-scroll" v-if="state.loading" :key="1">
                        <AgendamentoCardSkel v-for="n in 8" :key="n"/>
                    </div>

                    <div class="flex-1 overflow-x-hidden light-scroll" v-else :key="2">
                        <AgendamentoEmptyCard v-if="false"/>
                        <ExecucaoCard v-for="n in 5" :key="n"/>
                    </div>
                </Transition>
            </div>
        </div>

        <!-- Conteúdo -->
        <div class="bg-white border-l border-neutral-3 flex flex-col items-center justify-center p-20 relative overflow-hidden"
             style="width: calc(100% - 415px)">
            <h1 class="text-4xl font-bold mb-6">Auditoria em tempo real</h1>
            <p class="opacity-80 w-80 mb-14 text-center">Clique no botão abaixo para entrar no modo de visualização dos exames.</p>
            <button class="h-14 w-80 rounded-md bg-primary font-medium text-white  mb-10">Acessar transmissão</button>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, reactive } from 'vue';
import AgendamentoCardSkel from '@/components/AgendamentoCardSkel.vue';
import AgendamentoEmptyCard from '@/components/AgendamentoEmptyCard.vue';
import ExecucaoCard from './ExecucaoCard.vue';

const ExecucaoPage = defineComponent({
    components: { AgendamentoCardSkel,AgendamentoEmptyCard, ExecucaoCard },
    setup() {
        const state = reactive({
            loading: false
        });

        return { state };   
    }
});

export default ExecucaoPage;
</script>

<style>

</style>