import AswerQuestionDTO from '@/DTOs/AswerQuestionDTO';
import AuditoriaDTO from '@/DTOs/AuditoriaDTO';
import ExameDetailDTO from '@/DTOs/ExameDetailDTO';
import ExameDTO from '@/DTOs/ExameDTO';
import ExameTeoricoDTO from '@/DTOs/ExameTeoricoDTO';
import GetExameDTO from '@/DTOs/GetExameDTO';
import GravacaoQuery from '@/queryObjets/GravacaoQuery';
import { Get, Patch, Post } from './BaseService';

export default {
    GetExame: (data: GetExameDTO) => {
        return Get<ExameTeoricoDTO>(`teorico/exame/api/exames/cliente/${data.clientId}/agendamento/${data.agendamentoId}/usuario/${data.usuarioId}`);
    },
    GetExames:(query: GravacaoQuery) => {
        return Get<ExameDTO[]>('teorico/exame/api/gravacoes/', query);
    },
    GetExamDetail:(examId: number) => {
        return Get<ExameDetailDTO>(`teorico/exame/api/gravacoes/agendamento/${examId}`);
    },
    Auditar: (data: AuditoriaDTO) => {
        return Patch('teorico/exame/api/gravacoes/auditar', data);
    },
    AswerQuestion: (agendamentoId: number, data: AswerQuestionDTO) => {
        return Patch(`teorico/exame/api/gravacoes/${agendamentoId}/responder`, data);
    },
    StartExam: (agendamentoId: number, streamName: string): Promise<void> => {
        return new Promise((resolve, reject) =>{
            const [ requestPost ] = Post<{ urlVideo: string}, void>(`teorico/exame/api/gravacoes/${agendamentoId}/iniciar`, { urlVideo: `https://superauladev.blob.core.windows.net/teste/${streamName}_240p.mp4` });
            requestPost
                .then(() => {
                    const [ requestPatch ] = Patch<{}, void>(`easy-procto/agendamento/api/agendamentos/${agendamentoId}/status/Executado`, {});
                    return requestPatch;
                })
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    },
    FinishExam: (agendamentoId: number) => {
        return Post<{}, void>(`teorico/exame/api/gravacoes/${agendamentoId}/finalizar`, {});
    },
    UpdateFace: (agendamentoId: number, picture: string) => {
        return Patch<{face: string}, void>(`teorico/exame/api/gravacoes/${agendamentoId}/face`, { face: picture });
    },
    GetImage: (imageId: string) => {
        return Get<Blob>(`teorico/exame/api/imagens/${imageId}`);
    }
};