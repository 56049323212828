
import { defineComponent, onMounted, ref } from 'vue';

declare global {
    interface Window {
        VLibras: {
            Widget: {
                new (url: string): void;
            };
        };
    }
}

const VLibras = defineComponent({
    setup() {

        // Container
        const container = ref<HTMLDivElement |null>();

        // Posições
        const mouseStartPosition = { left: 0, bottom: 0 };
        const containerStartPosition = { left: 0, bottom: 0 };

        // Quando mover o mouse
        const onMouseMove = (e: MouseEvent) => {
            const diference = {
                left: e.pageX - mouseStartPosition.left,
                bottom: (window.innerHeight - e.pageY) - mouseStartPosition.bottom
            };

            requestAnimationFrame(() => {
                if(container.value != null){
                    container.value.style.left = `${containerStartPosition.left + diference.left}px`;
                    container.value.style.bottom = `${containerStartPosition.bottom + diference.bottom}px`;
                }
            });
        };

        // Quando finalizar o movimento
        const endMove = () => {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', endMove);
        };

        // Quando iniciar o movimento
        const startMove = (e: MouseEvent) => {
            // Setar posição inicial do container
            const box = container.value!.getBoundingClientRect();
            containerStartPosition.left = box.x;
            containerStartPosition.bottom = window.innerHeight - box.y - box.height;

            // Setar posição inicial do mouse
            mouseStartPosition.left = e.pageX;
            mouseStartPosition.bottom = window.innerHeight - e.pageY;
            document.body.addEventListener('mousemove', onMouseMove);
            document.body.addEventListener('mouseup', endMove);
        };

        const refresh = () => {
            const isOpened = document.querySelector('.active') == null;
            if(isOpened) {
                const closeButton = document.querySelector('.vpw-settings-btn-close') as HTMLElement;
                const openButton = document.querySelector('.access-button') as HTMLElement;
                if(closeButton && openButton) {
                    try {
                        closeButton!.click();
                        openButton!.click();
                    } catch (error) {
                        window.alert("Caiu aqui");                    
                    }
                }
            }
        };
        const script = document.createElement('script');

        const render = () => {
            script.removeEventListener('load', render);
            new window.VLibras.Widget('https://vlibras.gov.br/app');
            const interval = setInterval(() => {
                const closeButton = document.querySelector(".vpw-settings-btn-close");
                if(closeButton){
                    const onClose = () => {
                        container.value!.style.bottom = '10px';
                        container.value!.style.left = '110px';
                    };
                    closeButton.addEventListener('click', onClose);
                    clearInterval(interval);
                }
            },100);
        };

        const initialize = () => {
            script.src = 'https://vlibras.gov.br/app/vlibras-plugin.js';
            script.addEventListener('load', render);
            document.head.appendChild(script);
        };


        onMounted(() => initialize());

        return { startMove, endMove, container, render, refresh };
    }
});

export default VLibras;
