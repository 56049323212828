
import { defineComponent, reactive } from 'vue';
import AgendamentoCardSkel from '@/components/AgendamentoCardSkel.vue';
import AgendamentoEmptyCard from '@/components/AgendamentoEmptyCard.vue';
import ExecucaoCard from './ExecucaoCard.vue';

const ExecucaoPage = defineComponent({
    components: { AgendamentoCardSkel,AgendamentoEmptyCard, ExecucaoCard },
    setup() {
        const state = reactive({
            loading: false
        });

        return { state };   
    }
});

export default ExecucaoPage;
