
import { defineComponent } from 'vue';

const ArrowButton = defineComponent({
    props: {
        direction: {
            type: String,
            default: 'left'
        }
    }
});

export default ArrowButton;
