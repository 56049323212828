
import VLibras from '@/components/VLibras.vue';
import vm from '@/viewModels/MainViewModel';
import { defineComponent } from 'vue';

const App = defineComponent({
    components: { VLibras },
    setup() {
        return { 'vlibras': vm.vlibras };
    }
});

export default App;
