<template>
    <div class="fixed h-14 top-0 right-0 left-0 bg-white shadow-2 flex items-center z-40">
        <img src="@/assets/img/logo.svg" class="mx-7 w-36"/>
        <div class="flex items-center justify-end flex-1">
            <slot/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const Header = defineComponent({
});

export default Header;
</script>

<style>

</style>