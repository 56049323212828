<template>
    <svg preserveAspectRatio="xMidYMin slice" viewBox="0 0 860 360" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="868" height="374">
            <rect x="0.894043" y="0.0301514" width="867" height="373.4" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask0)">
            <path opacity="0.6" d="M256.894 227.388C36.0941 345.788 -282.773 340.055 -414.606 322.388V462.888H895.394C898.561 411.388 902.994 301.788 895.394 275.388C887.794 248.988 858.394 225.26 802.894 200.388C712.894 160.055 477.694 108.988 256.894 227.388Z" fill="#F8F8F8"/>
            <path opacity="0.6" d="M246.894 252.388C26.0941 370.788 -292.773 365.055 -424.606 347.388V487.888H885.394C888.561 436.388 892.994 326.788 885.394 300.388C877.794 273.988 848.394 250.26 792.894 225.388C702.894 185.055 467.694 133.988 246.894 252.388Z" fill="#F4F4F4"/>
            <path d="M52.394 333.531C-151.106 371.53 -362.939 364.031 -413.106 360.531V468.531L834.894 459.031C886.561 417.197 1020.79 381.531 962.394 371.531C903.994 361.531 986.394 309.53 865.394 309.531C583.894 270.03 362.067 275.705 52.394 333.531Z" fill="#C4D6D0"/>
        </g>
        <path d="M507.909 166.587L489.691 228.048C488.859 230.769 486.364 232.659 483.492 232.659L409.634 232.886C405.703 232.886 402.603 229.635 402.83 225.704C403.057 222.076 406.005 219.203 409.634 219.203H437.907L460.51 156.155H500.047C505.49 156.155 509.421 161.371 507.909 166.587Z" fill="#455A64"/>
        <path d="M462.173 224.495C452.862 224.495 445.315 224.393 445.315 224.268C445.315 224.143 452.862 224.041 462.173 224.041C471.483 224.041 479.031 224.143 479.031 224.268C479.031 224.393 471.483 224.495 462.173 224.495Z" fill="#263238"/>
        <path d="M502.465 158.121C502.314 158.045 497.324 172.787 491.201 191.005C485.153 209.224 480.315 223.966 480.466 224.041C480.617 224.117 485.607 209.375 491.73 191.157C497.778 172.938 502.541 158.196 502.465 158.121Z" fill="#263238"/>
        <path d="M448.414 287.24L452.95 287.164V232.659H448.414V287.24Z" fill="#455A64"/>
        <path d="M471.622 287.013H429.741L448.716 280.436H453.252L471.622 287.013Z" fill="#455A64"/>
        <path d="M482.281 142.094L475.326 176.718L476.536 202.647L429.893 194.861L431.707 172.106V153.283L420.368 175.508L411.447 158.348L427.776 128.865C430.724 125.765 434.277 123.498 438.435 121.986C440.476 121.23 442.593 120.776 445.088 120.7C450.455 122.364 455.822 122.364 461.19 120.7C461.87 120.776 462.777 120.852 463.911 121.003C463.987 121.003 464.062 121.003 464.138 121.003C474.041 122.212 481.601 130.377 482.13 140.356L482.281 142.094Z" fill="#857D8E"/>
        <path d="M463.987 120.927C463.987 120.927 483.113 121.078 485.532 141.263L485.834 156.08L469.354 155.928L463.987 120.927Z" fill="#857D8E"/>
        <path d="M418.176 147.008C418.176 147.159 433.22 147.235 451.817 147.235C470.414 147.235 485.458 147.084 485.458 147.008C485.458 146.857 470.414 146.781 451.817 146.781C433.296 146.706 418.176 146.857 418.176 147.008Z" fill="#FAFAFA"/>
        <path d="M418.176 150.183C418.176 150.334 433.22 150.41 451.817 150.41C470.414 150.41 485.458 150.259 485.458 150.183C485.458 150.032 470.414 149.956 451.817 149.956C433.296 149.956 418.176 150.032 418.176 150.183Z" fill="#FAFAFA"/>
        <path d="M419.309 144.286C419.309 144.438 434.126 144.513 452.42 144.513C470.715 144.513 485.532 144.362 485.532 144.286C485.532 144.135 470.715 144.06 452.42 144.06C434.126 144.06 419.309 144.211 419.309 144.286Z" fill="#FAFAFA"/>
        <path d="M431.436 157.198C431.214 147.097 431.135 138.905 431.261 138.902C431.386 138.9 431.668 147.087 431.89 157.188C432.112 167.29 432.191 175.481 432.066 175.484C431.941 175.487 431.659 167.3 431.436 157.198Z" fill="#263238"/>
        <path d="M438.588 162.203C438.588 162.203 429.516 162.883 427.929 165.227C426.265 167.646 423.846 174.45 424.753 174.525C425.661 174.601 426.417 173.996 426.417 173.996L428.911 169.611C428.911 169.611 428.533 173.845 429.97 173.92C431.482 173.996 438.814 175.508 438.814 175.508L438.588 162.203Z" fill="#FFBE9D"/>
        <path d="M485.835 152.224V175.584C485.533 181.027 480.392 184.958 475.1 183.748L438.587 176.188L438.663 161.22L470.186 167.268L470.64 151.393L485.835 152.224Z" fill="#857D8E"/>
        <path d="M464.97 181.858C464.97 181.858 464.819 181.782 464.517 181.707C464.139 181.631 463.685 181.556 463.08 181.404C461.795 181.102 459.981 180.724 457.713 180.271C453.026 179.288 446.525 178.003 438.738 176.415L438.889 176.642C438.889 171.955 438.889 166.739 438.889 161.296L438.587 161.523C440.628 161.901 442.82 162.354 445.013 162.732C454.235 164.471 462.853 166.134 470.111 167.495L470.413 167.57V167.268C470.186 160.313 469.96 154.492 469.808 150.41C469.733 148.444 469.657 146.857 469.582 145.723C469.582 145.194 469.506 144.816 469.506 144.513C469.506 144.211 469.506 144.06 469.43 144.06C469.43 144.06 469.43 144.211 469.43 144.513C469.43 144.816 469.43 145.269 469.43 145.723C469.43 146.857 469.506 148.444 469.506 150.41C469.582 154.492 469.733 160.313 469.884 167.268L470.111 167.041C462.853 165.605 454.235 163.942 445.013 162.203C442.82 161.749 440.628 161.371 438.587 160.993L438.284 160.918V161.22C438.284 166.739 438.284 171.879 438.284 176.566V176.793H438.436C446.222 178.305 452.799 179.59 457.486 180.497C459.754 180.951 461.568 181.253 462.853 181.48C463.458 181.556 463.912 181.631 464.29 181.707C464.819 181.858 464.97 181.934 464.97 181.858Z" fill="#263238"/>
        <path d="M444.03 120.927C444.03 120.927 443.954 121.003 443.879 121.305C443.803 121.532 443.728 121.91 443.576 122.363C443.425 123.271 443.274 124.631 443.727 126.294C444.181 127.882 445.239 129.696 446.903 131.208C448.566 132.72 450.985 133.779 453.555 133.703C456.125 133.703 458.62 132.645 460.132 131.057C461.72 129.394 462.4 127.428 462.551 125.765C462.702 124.102 462.4 122.741 462.098 121.834C461.946 121.381 461.795 121.078 461.644 120.851C461.493 120.625 461.417 120.549 461.417 120.549C461.342 120.549 461.644 121.003 461.946 121.91C462.249 122.741 462.475 124.102 462.249 125.69C462.022 127.277 461.342 129.167 459.83 130.679C458.393 132.191 456.05 133.174 453.631 133.174C451.136 133.174 448.868 132.267 447.281 130.83C445.693 129.394 444.635 127.731 444.181 126.219C443.728 124.707 443.728 123.346 443.879 122.439C443.879 121.456 444.03 121.003 444.03 120.927Z" fill="#263238"/>
        <path d="M442.216 177.473C442.291 177.473 442.291 176.566 442.216 175.206C442.14 173.769 442.065 171.804 442.065 169.687C442.065 167.495 442.065 165.605 442.14 164.168C442.14 162.732 442.216 161.901 442.14 161.901C442.065 161.901 441.913 162.732 441.762 164.168C441.611 165.605 441.535 167.57 441.535 169.763C441.535 171.955 441.687 173.92 441.838 175.357C441.989 176.642 442.216 177.473 442.216 177.473Z" fill="#FAFAFA"/>
        <path d="M444.106 177.851C444.182 177.851 444.182 176.944 444.182 175.583C444.182 173.996 444.182 172.106 444.106 170.065C444.106 168.024 444.106 166.134 444.106 164.546C444.106 163.11 444.106 162.278 444.03 162.278C443.955 162.278 443.879 163.11 443.728 164.546C443.652 165.983 443.577 167.873 443.577 170.065C443.577 172.257 443.652 174.147 443.804 175.583C443.955 177.02 444.03 177.851 444.106 177.851Z" fill="#FAFAFA"/>
        <path d="M407.593 270.609L400.714 289.583L390.886 303.04C390.206 304.022 390.433 305.383 391.491 305.988C392.096 306.366 392.776 306.441 393.381 306.139C397.312 304.476 411.827 298.428 412.28 296.992C412.81 295.329 422.108 274.918 422.108 274.918L407.593 270.609Z" fill="#C7C2CC"/>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M391.414 306.063L412.657 295.782L412.354 296.614C412.052 297.294 411.598 297.899 410.918 298.201C409.179 299.108 404.265 301.603 393.304 306.215C392.775 306.517 392.019 306.441 391.414 306.063Z" fill="white"/>
            </g>
        </g>
        <g opacity="0.6">
            <g opacity="0.6">
                <g opacity="0.6">
                    <path opacity="0.6" d="M411.221 288.223C410.616 288.374 410.162 289.054 410.314 289.659C410.465 290.264 411.069 290.717 411.674 290.566C412.279 290.415 412.808 289.659 412.657 289.054C412.506 288.449 411.674 287.996 411.145 288.223" fill="white"/>
                </g>
            </g>
        </g>
        <path d="M398.975 299.562C398.824 299.562 398.824 298.428 397.917 297.445C397.01 296.463 395.876 296.312 395.876 296.16C395.876 296.085 396.178 296.085 396.632 296.16C397.085 296.236 397.69 296.538 398.219 297.143C398.748 297.672 398.975 298.353 399.051 298.806C399.051 299.26 398.975 299.562 398.975 299.562Z" fill="#263238"/>
        <path d="M396.631 301.528C396.48 301.603 396.177 300.696 395.27 300.091C394.438 299.411 393.531 299.26 393.531 299.109C393.531 298.957 394.59 298.957 395.572 299.713C396.555 300.469 396.706 301.528 396.631 301.528Z" fill="#263238"/>
        <path d="M402.678 297.294C402.527 297.37 401.997 296.009 400.712 294.875C399.503 293.741 398.142 293.287 398.142 293.136C398.142 293.061 399.654 293.287 401.015 294.573C402.375 295.782 402.829 297.218 402.678 297.294Z" fill="#263238"/>
        <path d="M405.25 291.624C405.174 291.7 404.267 290.868 402.982 290.264C401.621 289.659 400.412 289.583 400.487 289.432C400.487 289.356 401.772 289.205 403.209 289.81C404.57 290.566 405.401 291.624 405.25 291.624Z" fill="#263238"/>
        <path d="M407.441 286.106C407.365 286.181 406.534 285.123 405.098 284.67C403.661 284.14 402.376 284.443 402.376 284.292C402.376 284.216 402.678 284.065 403.208 283.989C403.737 283.914 404.493 283.914 405.249 284.216C406.005 284.518 406.61 284.972 406.987 285.35C407.365 285.803 407.517 286.106 407.441 286.106Z" fill="#263238"/>
        <path d="M405.551 283.687C405.551 283.687 405.551 283.384 405.778 282.931C406.005 282.477 406.383 281.872 407.063 281.268C407.366 280.965 407.819 280.663 408.424 280.587C408.726 280.587 409.104 280.739 409.255 281.117C409.407 281.419 409.331 281.797 409.255 282.099C408.802 283.309 407.29 284.065 405.854 283.687C404.417 283.309 403.51 282.024 403.359 280.814C403.359 280.512 403.359 280.134 403.661 279.907C403.964 279.605 404.342 279.68 404.644 279.831C405.173 280.134 405.4 280.587 405.627 280.965C406.08 281.721 406.232 282.477 406.307 282.931C406.383 283.384 406.383 283.687 406.307 283.687C406.232 283.687 406.156 282.553 405.249 281.117C405.022 280.739 404.72 280.361 404.342 280.134C404.19 280.058 403.964 280.058 403.813 280.209C403.661 280.361 403.661 280.587 403.661 280.814C403.813 281.872 404.644 283.006 405.854 283.309C407.063 283.611 408.348 282.931 408.726 282.024C408.802 281.797 408.877 281.57 408.802 281.343C408.726 281.192 408.575 281.041 408.348 281.041C407.895 281.041 407.517 281.343 407.214 281.57C405.929 282.628 405.627 283.687 405.551 283.687Z" fill="#263238"/>
        <path d="M378.412 265.695L379.017 281.116C379.017 281.116 363.519 286.937 363.368 289.81L393.38 289.735L393.456 265.619L378.412 265.695Z" fill="#C7C2CC"/>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M388.314 279.756C388.919 279.907 389.297 280.587 389.146 281.117C388.995 281.721 388.39 282.099 387.785 281.948C387.18 281.797 386.727 281.041 386.878 280.436C387.105 279.831 387.861 279.453 388.39 279.68" fill="white"/>
            </g>
        </g>
        <g opacity="0.6">
            <path opacity="0.6" d="M393.304 289.659V287.24L364.501 288.223C364.501 288.223 363.141 288.827 363.292 289.735L393.304 289.659Z" fill="white"/>
        </g>
        <path d="M378.563 280.965C378.563 281.117 379.319 281.192 379.999 281.646C380.755 282.099 381.133 282.704 381.209 282.704C381.36 282.704 381.133 281.797 380.226 281.268C379.394 280.663 378.487 280.814 378.563 280.965Z" fill="#263238"/>
        <path d="M375.388 282.251C375.388 282.402 375.993 282.628 376.522 283.233C377.051 283.838 377.202 284.518 377.278 284.518C377.429 284.518 377.505 283.687 376.9 282.931C376.219 282.175 375.388 282.099 375.388 282.251Z" fill="#263238"/>
        <path d="M373.573 286.03C373.724 286.03 373.876 285.35 373.498 284.518C373.12 283.687 372.439 283.384 372.364 283.536C372.288 283.687 372.742 284.065 373.044 284.745C373.422 285.426 373.498 286.03 373.573 286.03Z" fill="#263238"/>
        <path d="M378.79 277.412C378.865 277.563 379.546 277.337 380.377 277.412C381.209 277.412 381.814 277.715 381.965 277.563C382.04 277.488 381.436 276.883 380.453 276.883C379.395 276.883 378.714 277.337 378.79 277.412Z" fill="#263238"/>
        <g opacity="0.3">
            <path opacity="0.3" d="M378.562 268.719L378.638 271.516L393.379 271.289V268.794L378.562 268.719Z" fill="black"/>
        </g>
        <path d="M378.713 274.313C378.713 274.388 379.318 274.464 380.225 274.313C380.679 274.237 381.208 274.086 381.813 273.859C382.115 273.708 382.417 273.557 382.644 273.406C382.795 273.33 382.947 273.255 383.098 273.028C383.249 272.877 383.325 272.499 383.173 272.272C383.022 272.045 382.72 271.969 382.493 271.969C382.266 271.969 382.115 272.045 381.964 272.045C381.662 272.121 381.284 272.272 381.057 272.347C380.452 272.574 379.998 272.952 379.696 273.254C379.016 273.935 378.789 274.464 378.789 274.464C378.864 274.54 379.167 274.086 379.923 273.481C380.301 273.179 380.754 272.952 381.284 272.725C381.586 272.65 381.813 272.499 382.115 272.423C382.417 272.347 382.72 272.347 382.795 272.499C382.795 272.574 382.795 272.574 382.795 272.65C382.72 272.725 382.569 272.801 382.493 272.877C382.191 273.028 381.964 273.179 381.737 273.33C381.208 273.557 380.679 273.784 380.301 273.859C379.242 274.162 378.713 274.237 378.713 274.313Z" fill="#263238"/>
        <path d="M378.941 274.54C379.016 274.54 379.319 274.011 379.319 273.103C379.319 272.65 379.319 272.045 379.092 271.44C379.016 271.138 378.865 270.835 378.714 270.533C378.563 270.231 378.336 269.853 377.731 269.777C377.429 269.777 377.202 270.004 377.127 270.231C377.051 270.457 376.975 270.609 376.975 270.76C376.9 271.138 376.9 271.44 376.975 271.743C377.051 272.347 377.353 272.877 377.656 273.33C378.26 274.086 378.865 274.313 378.865 274.237C378.941 274.162 378.412 273.859 377.958 273.103C377.731 272.725 377.505 272.272 377.505 271.667C377.505 271.365 377.505 271.062 377.505 270.835C377.58 270.533 377.656 270.231 377.807 270.306C377.958 270.306 378.185 270.533 378.336 270.76C378.487 271.062 378.638 271.289 378.714 271.591C378.941 272.121 379.016 272.65 379.016 273.103C379.016 273.935 378.865 274.464 378.941 274.54Z" fill="#263238"/>
        <path d="M393.381 191.61C411.07 193.424 434.581 195.843 434.581 195.843L431.406 224.721C431.406 224.721 396.858 215.574 397.161 216.33C397.387 217.086 395.573 277.715 395.573 277.715H374.784L374.406 209.073C374.179 198.716 383.1 190.552 393.381 191.61Z" fill="#263238"/>
        <path d="M476.991 203.252V212.172C476.991 218.296 470.036 224.721 463.988 224.721H459.301C455.597 224.721 449.776 222 446.752 219.808L444.938 218.522L423.317 285.577C423.317 285.577 398.521 281.117 398.521 280.361C398.521 279.605 415.304 225.477 423.015 200.304C426.19 190.023 437.151 184.05 447.357 187.528C447.735 187.679 448.037 187.755 448.415 187.906C459.452 192.366 476.991 203.252 476.991 203.252Z" fill="#263238"/>
        <path d="M398.521 280.36C398.521 280.36 398.597 280.285 398.597 280.134C398.673 279.907 398.748 279.68 398.899 279.453C399.126 278.849 399.504 277.866 399.882 276.656C400.714 274.237 401.848 270.684 403.208 266.299C406.005 257.53 409.634 245.435 413.716 232.054C415.757 225.326 417.647 218.976 419.386 213.155C420.218 210.282 421.049 207.485 421.805 204.915C422.183 203.63 422.561 202.345 422.939 201.211C423.317 200.001 423.62 198.867 424.073 197.809C424.905 195.692 426.265 194.105 427.551 192.895C428.911 191.685 430.121 190.778 431.179 190.022C432.238 189.342 433.069 188.813 433.674 188.435C433.901 188.284 434.127 188.132 434.354 188.057C434.505 187.981 434.581 187.906 434.581 187.906C434.581 187.906 434.505 187.906 434.354 187.981C434.203 188.057 433.976 188.208 433.674 188.359C433.069 188.662 432.238 189.191 431.104 189.871C430.045 190.552 428.76 191.459 427.324 192.668C425.963 193.878 424.602 195.541 423.695 197.658C423.242 198.716 422.864 199.85 422.486 201.059C422.108 202.269 421.73 203.479 421.352 204.764C420.596 207.334 419.764 210.131 418.857 213.004C417.118 218.825 415.153 225.175 413.187 231.903C409.181 245.284 405.476 257.379 402.83 266.148C401.47 270.533 400.411 274.01 399.655 276.505C399.277 277.715 398.975 278.622 398.748 279.302C398.673 279.604 398.597 279.831 398.521 280.058C398.521 280.285 398.521 280.36 398.521 280.36Z" fill="#455A64"/>
        <path d="M393.305 207.636C393.154 207.712 394.364 209.526 395.346 211.87C396.329 214.289 396.783 216.33 396.934 216.33C397.085 216.33 396.858 214.138 395.8 211.643C394.817 209.224 393.381 207.561 393.305 207.636Z" fill="#455A64"/>
        <path d="M389.299 210.433C389.223 210.585 391.037 211.341 392.852 212.853C394.742 214.289 395.876 215.876 396.027 215.801C396.178 215.725 395.12 213.987 393.154 212.475C391.34 210.887 389.374 210.358 389.299 210.433Z" fill="#455A64"/>
        <path d="M423.089 285.577C423.24 285.652 429.061 267.66 436.167 245.51C443.273 223.361 448.867 205.293 448.716 205.293C448.565 205.217 442.744 223.209 435.638 245.359C428.532 267.509 422.938 285.501 423.089 285.577Z" fill="#455A64"/>
        <path d="M449.624 180.951L378.789 181.631L365.56 144.286L417.117 144.664L429.061 178.229L449.624 178.532V180.951Z" fill="#455A64"/>
        <path d="M392.683 163.751C392.462 162.18 393.555 160.727 395.126 160.505C396.697 160.283 398.151 161.376 398.373 162.947C398.594 164.518 397.501 165.972 395.93 166.194C394.359 166.416 392.905 165.322 392.683 163.751Z" fill="white"/>
        <path d="M455.371 79.878C454.691 77.3833 452.801 76.1737 450.382 75.3422C447.962 74.5106 445.09 74.9642 443.049 76.5517C441.688 77.6101 440.63 79.122 438.966 79.6512C436.321 80.5584 433.145 78.5928 430.575 79.8024C429.063 80.4828 428.156 82.3727 428.61 83.9602C429.063 85.5477 430.651 86.8329 432.314 86.7573C430.575 87.3621 430.046 89.8568 431.029 91.4443C432.012 93.0318 434.128 93.561 435.943 93.2586C437.757 92.8806 439.344 91.8223 440.781 90.5371C442.141 89.3276 443.427 87.8912 444.863 86.7573C446.375 85.6233 448.114 84.7918 450.004 84.8674C452.498 85.0186 454.691 86.9085 455.824 89.1764C456.958 91.4443 457.185 94.0146 457.336 96.5093C457.488 99.004 457.488 101.574 458.244 103.993C458.319 104.296 458.47 104.598 458.773 104.674C459.151 104.825 459.529 104.371 459.756 103.993C462.023 99.7599 463.46 95.0729 463.989 90.2347C464.291 87.4377 464.216 84.7918 462.704 82.4483C461.192 80.1048 457.336 78.4416 455.371 79.878Z" fill="#263238"/>
        <path d="M460.284 91.5956L461.569 119.793C461.796 124.556 461.115 131.662 453.556 131.662C446.979 131.133 445.013 125.009 444.787 120.549C444.711 118.432 444.635 116.694 444.56 116.467C444.56 116.467 437.378 116.089 436.471 109.058C436.093 105.657 435.791 98.5505 435.639 92.0491C435.488 85.0942 440.629 79.2733 447.508 78.5173L448.188 78.4417C455.673 78.0637 460.133 84.1115 460.284 91.5956Z" fill="#FFBE9D"/>
        <path d="M444.558 116.316C444.558 116.316 449.094 116.24 453.554 112.687C453.554 112.687 451.891 118.054 444.785 118.13L444.558 116.316Z" fill="#EB996E"/>
        <path d="M438.284 96.5093C438.36 97.0384 438.813 97.492 439.342 97.4164C439.872 97.4164 440.325 96.9628 440.325 96.3581C440.25 95.8289 439.796 95.3753 439.267 95.4509C438.662 95.5265 438.208 95.9801 438.284 96.5093Z" fill="#263238"/>
        <path d="M437.602 95.2998C437.754 95.451 438.434 94.7706 439.568 94.6194C440.626 94.4682 441.533 94.9974 441.609 94.8462C441.685 94.7706 441.533 94.5438 441.155 94.317C440.777 94.0903 440.173 93.9391 439.492 94.0147C438.812 94.0903 438.283 94.3926 437.98 94.695C437.602 94.9974 437.527 95.2998 437.602 95.2998Z" fill="#263238"/>
        <path d="M448.942 95.7534C449.017 96.2826 449.471 96.7362 450 96.6606C450.529 96.6606 450.983 96.207 450.983 95.6022C450.907 95.073 450.454 94.6194 449.925 94.695C449.395 94.7706 448.942 95.2242 448.942 95.7534Z" fill="#263238"/>
        <path d="M448.338 94.3927C448.489 94.5439 449.169 93.8635 450.303 93.7123C451.362 93.5611 452.269 94.0903 452.344 93.9391C452.42 93.8635 452.269 93.6367 451.891 93.4099C451.513 93.1832 450.908 93.032 450.228 93.1076C449.547 93.1832 449.018 93.4855 448.716 93.7879C448.338 94.0903 448.262 94.3171 448.338 94.3927Z" fill="#263238"/>
        <path d="M445.239 102.859C445.239 102.784 444.558 102.784 443.424 102.708C443.122 102.708 442.895 102.708 442.82 102.482C442.744 102.255 442.82 101.952 442.895 101.65C443.122 100.97 443.273 100.214 443.5 99.4576C444.332 96.2826 444.861 93.7879 444.785 93.7123C444.634 93.7123 443.878 96.207 443.046 99.3064C442.82 100.062 442.668 100.818 442.517 101.499C442.442 101.801 442.291 102.179 442.517 102.633C442.593 102.859 442.82 102.935 443.046 103.011C443.198 103.011 443.424 103.011 443.5 103.011C444.558 103.011 445.239 102.935 445.239 102.859Z" fill="#263238"/>
        <path d="M448.489 103.313C448.338 103.313 448.413 104.523 447.506 105.43C446.599 106.413 445.314 106.413 445.314 106.564C445.314 106.639 445.616 106.791 446.146 106.715C446.675 106.715 447.431 106.413 447.96 105.883C448.489 105.279 448.716 104.598 448.716 104.069C448.716 103.54 448.565 103.313 448.489 103.313Z" fill="#263238"/>
        <path d="M447.582 90.1593C447.733 90.4617 448.792 90.1593 450.077 90.2349C451.362 90.2349 452.42 90.4617 452.572 90.2349C452.647 90.0837 452.42 89.8569 451.967 89.6301C451.513 89.4033 450.833 89.1765 450.077 89.1765C449.321 89.1765 448.641 89.4033 448.187 89.6301C447.733 89.7813 447.507 90.0081 447.582 90.1593Z" fill="#263238"/>
        <path d="M437.602 92.1249C437.829 92.3517 438.51 92.0493 439.417 91.8981C440.324 91.7469 441.08 91.8981 441.231 91.5958C441.307 91.4446 441.155 91.2178 440.778 90.991C440.4 90.7642 439.87 90.6886 439.266 90.7642C438.661 90.8398 438.132 91.1422 437.829 91.4446C437.602 91.7469 437.527 92.0493 437.602 92.1249Z" fill="#263238"/>
        <path d="M460.209 96.8873C460.36 96.8117 464.896 94.9218 465.274 99.76C465.651 104.598 460.662 103.993 460.662 103.842C460.662 103.767 460.209 96.8873 460.209 96.8873Z" fill="#FFBE9D"/>
        <path d="M462.022 101.952C462.022 101.952 462.098 102.028 462.249 102.028C462.4 102.103 462.627 102.103 462.854 102.028C463.383 101.801 463.761 100.97 463.685 100.062C463.685 99.6088 463.534 99.2308 463.383 98.8528C463.232 98.4748 462.93 98.248 462.703 98.248C462.476 98.1724 462.249 98.3992 462.249 98.5504C462.174 98.7016 462.249 98.7772 462.174 98.7772C462.174 98.7772 462.098 98.7016 462.098 98.4748C462.098 98.3992 462.174 98.248 462.249 98.1724C462.325 98.0968 462.552 98.0212 462.703 98.0212C463.081 98.0212 463.459 98.3236 463.61 98.7016C463.837 99.0796 463.988 99.5332 463.988 100.062C464.063 101.045 463.61 102.028 462.93 102.255C462.627 102.406 462.325 102.255 462.174 102.179C462.098 102.028 462.022 101.952 462.022 101.952Z" fill="#EB996E"/>
        <path d="M434.051 90.6884C437.226 91.2176 440.704 90.008 442.896 87.6645C444.484 86.0014 445.542 83.8091 447.659 82.9019C449.095 82.2971 450.682 82.4483 452.119 83.0531C453.555 83.6579 454.765 84.6406 455.747 85.7746C454.765 89.5544 456.125 93.4855 457.411 97.1141C457.562 97.4921 457.713 97.9457 458.091 98.1725C458.696 98.5504 459.527 98.0969 459.981 97.5677C460.661 96.6605 460.888 95.451 460.964 94.2414C461.19 91.5955 460.964 88.8741 460.056 86.3794C459.225 83.8847 457.713 81.5412 455.596 79.9536C452.95 77.9125 449.473 77.081 446.222 77.5345C442.896 77.9125 439.797 79.5001 437.377 81.768C436.243 82.8263 435.261 84.0359 434.732 85.5478" fill="#263238"/>
        <path d="M545.104 181.178V187.906H508.212V287.164H504.81V187.906L350.744 188.662V292.226H347.418V188.435L323.605 188.662V180.648L545.104 181.178Z" fill="#263238"/>
        <path d="M521.516 186.545L506.926 197.053L508.211 198.262L522.877 187.225L521.516 186.545Z" fill="#263238"/>
        <path d="M333.66 187.528L350.593 199.548L349.308 200.757L332.299 188.208L333.66 187.528Z" fill="#263238"/>
        <path d="M453.329 178.759H491.43V181.556H453.329V178.759Z" fill="#263238"/>
    </svg>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const HomeIllustration = defineComponent({
});

export default HomeIllustration;
</script>

<style>

</style>