<template>
    <div class="bg-white border-l border-neutral-3 flex flex-col items-stretch relative overflow-hidden"
         style="width: calc(100% - 415px)">
         
        <!-- Header -->
        <div class="h-18 shadow-md flex items-center px-7 relative z-10">
            <p class="flex-1"><b>{{ exame.cpf }}  •  </b>Horário: {{ formattedBegin }} / {{ formattedEnd }} - {{ exame.status }}</p>

            <!-- Menu -->
            <div class="cursor-pointer bg-neutral-1 px-2 py-1 text-sm rounded-md text-opacity-80 text-black mx-1 transition-colors duration-200"
                 :class="{'bg-primary text-white text-opacity-100': page == 'video'}"
                 @click="page = 'video'">
                Vídeo
            </div>
            <!-- <div class="cursor-pointer bg-neutral-1 px-2 py-1 text-sm rounded-md text-opacity-80 text-black mx-1 transition-colors duration-200"
                 :class="{'bg-primary text-white text-opacity-100': page == 'imagens'}"
                 @click="page = 'imagens'">
                Imagens
            </div> -->
            <!-- <div class="cursor-pointer bg-neutral-1 px-2 py-1 text-sm rounded-md text-opacity-80 text-black mx-1 transition-colors duration-200"
                 :class="{'bg-primary text-white text-opacity-100': page == 'respostas'}"
                 @click="page = 'respostas'">
                Respostas
            </div> -->
        </div>

        <Video v-if="page == 'video'" :urlVideo="exame.urlVideo" :alertas="exame.alertas" 
               :agendamentoId="exame.agendamentoId"/>

        <Imagens v-else-if="page == 'imagens'" :questoes="exame.questoes"/>

        <Respostas v-else-if="page == 'respostas'"/>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, PropType, ref } from 'vue';


import Respostas from './Respostas.vue';
import Video from './Video.vue';
import Imagens from './Imagens.vue';
import ExameDetailDTO from '@/DTOs/ExameDetailDTO';
import { format, parse } from 'date-fns';

const ExameDetailPage = defineComponent({
    components: { Respostas, Video, Imagens },
    props: {
        exame: {
            type: Object as PropType<ExameDetailDTO>,
            required: true
        }
    },
    setup(props) {
        const page = ref<'video' | 'imagens' | 'respostas'>("video");

        const formattedBegin = computed(() => {
            const date = parse(props.exame.dataHoraInicio, "dd/MM/yyyy HH:mm", new Date());
            return format(date, "HH'h'mm");
        });

        const formattedEnd = computed(() => {
            const date = parse(props.exame.dataHoraFim, "dd/MM/yyyy HH:mm", new Date());
            return format(date, "HH'h'mm");
        });

        
        return { page, formattedBegin, formattedEnd };
    }
});

export default ExameDetailPage;
</script>

<style>

</style>