
import ExameDTO from '@/DTOs/ExameDTO';
import { computed, defineComponent, PropType } from 'vue';

const ExameCard = defineComponent({
    props: {
        exame: {
            type: Object as PropType<ExameDTO>,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    setup(props){
        const maskedCpf = computed(() => {
            return `${props.exame.cpf.slice(0,3)}.${props.exame.cpf.slice(3,6)}.${props.exame.cpf.slice(6,9)}-${props.exame.cpf.slice(9,11)}`;
        });

        const status = computed(() => {
            switch (props.exame.statusAprovacao) {
                case 0:
                    return "Em análise";
                case 1: 
                    return "Aprovado";
                case 2:
                    return "Reprovado";
                default:
                    return "";
            }
        });

        const borderColor = computed(() => {
            if(!props.selected) {
                return 'transparent';
            }
            else {
                switch (props.exame.statusAprovacao) {
                    case 0:
                        return "#DF945D";
                    case 1: 
                        return "#04986D";
                    case 2:
                        return "#D95454";
                    default:
                        return "";
                }
            }
        });

        return { status, maskedCpf, borderColor };
    }
});

export default ExameCard;
