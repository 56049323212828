<template>
    <button :class="$attrs['small'] ? 'w-12 h-12' : 'h-14 w-14'"
            class="rounded-lg
                   flex items-center justify-center
                   shadow-2 transition duration-300 active:scale-90 active:bg-neutral-3
                   disabled:cursor-not-allowed" @click="$emit('pressed')">

        <img class="w-4 transform" src="@/assets/icons/arrow_left.svg" 
             :class="[{'rotate-180': direction == 'right'}, {'opacity-60': $attrs['disabled']}]"/>
    </button>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const ArrowButton = defineComponent({
    props: {
        direction: {
            type: String,
            default: 'left'
        }
    }
});

export default ArrowButton;
</script>

<style>

</style>