<template>
    <!-- Respostas -->
    <div class="flex-1 flex flex-col items-stretch overflow-hidden">

        <div class="flex-1 overflow-y-auto px-32 py-6">
            <p class="mb-2">Questão 02/20</p>
            <p class="font-medium">O que pode favorecer para ocorrer aquaplanagem ou hidroplanagem?</p>
            <div class="h-px bg-neutral-2 my-6"></div>
            <p class="my-10">
                A <span class="mx-4">-</span> Pneus carecas e pista seca
            </p>
            <p class="my-10">
                B <span class="mx-4">-</span> alta e pista seca
            </p>
            <p class="my-10 font-bold text-red-500">
                C <span class="mx-4">-</span> carecas e pista seca
            </p>
            <p class="my-10">
                E <span class="mx-4">-</span> Pneus carecas e pista seca
            </p>
        </div>

        <!-- Opções -->
        <div class="flex justify-between px-32 py-6">
            <ArrowButton small="true"/>
            <button class="h-12 flex-1 mx-4 rounded-lg border border-primary text-primary font-medium">
                Resposta correta
            </button>
            <button class="h-12 flex-1 mx-4 rounded-lg border border-neutral-black text-neuborder-neutral-black font-medium">
                Revisão
            </button>
            <ArrowButton small="true" direction="right"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, ref } from 'vue';
import ArrowButton from '@/components/ArrowButton.vue';

const Respostas = defineComponent({
    components: { ArrowButton },
    setup() {
        const teste = ref(0);
        return { teste };
    }
});

export default Respostas;
</script>

<style>

</style>