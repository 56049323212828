<template>
    <!-- Imagens -->
    <div class="flex-1 overflow-auto" >
        <div class="grid grid-cols-2 2xl:grid-cols-3 p-5 gap-5">
            <ImageCard v-for="(questao, index) in questoes" :key="index" :questao="questao"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue';
import RespostaQuestaoDTO from '@/DTOs/RespostaQuestaoDTO';
import ImageCard from './ImageCard.vue';

const Imagens = defineComponent({
    props: {
        questoes: {
            type: Array as PropType<RespostaQuestaoDTO[]>,
            required: true
        }
    },
    components: { ImageCard }
});

export default Imagens;
</script>

<style>

</style>