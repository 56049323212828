
import RespostaQuestaoDTO from '@/DTOs/RespostaQuestaoDTO';
import { format, parse } from 'date-fns';
import { computed, defineComponent, PropType } from 'vue';

const ImageCard = defineComponent({
    props: {
        questao: {
            type: Object as PropType<RespostaQuestaoDTO>,
            required: true
        }
    },
    setup(props) {
        const formattedTime = computed(() => {
            const date = parse(props.questao.dataHora, "dd/MM/yyyy HH:mm", new Date());
            return format(date, "HH'h'mm");
        });

        return { formattedTime };
    }
});

export default ImageCard;
