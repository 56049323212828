<template>
    <div class="h-screen flex items-center relative">

        <!-- Conteúdo -->
        <div class="w-2/5 mx-32 max-w-screen-sm flex flex-col">
            <p class="text-4xl font-bold mb-6">Escolha a opção para que seja exibida a listagem</p>
            <p class="opacity-80 mb-20">Realize o companhamento das provas em tempo real ou execute a auditoria de exames já concluídos.</p>

            <!-- <button class="h-14 w-80 bg-primary rounded-lg text-white font-medium mb-6" @click="$router.push({name: 'execucao'})">
                Exames em execução
            </button> -->
            <button class="h-14 w-80 border border-primary rounded-lg text-primary font-medium" @click="$router.push({name: 'exames'})">
                Lista de provas realizadas
            </button>
        </div>

        <!-- Ilustração -->
        <div class="flex-1 flex justify-center">
            <FinishIllustration class="w-full" style="max-width: 26rem"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';

const MenuPage = defineComponent({
    components: { FinishIllustration }
});

export default MenuPage;
</script>

<style>

</style>