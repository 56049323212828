<template>
    <RouterView #default="{ Component }">
        <Transition name="fade" mode="out-in">
            <component :is="Component"/>
        </Transition>
    </RouterView>

    <!-- VLibras -->
    <!-- <VLibras ref="vlibras"/> -->
</template>

<script lang='ts'>
import VLibras from '@/components/VLibras.vue';
import vm from '@/viewModels/MainViewModel';
import { defineComponent } from 'vue';

const App = defineComponent({
    components: { VLibras },
    setup() {
        return { 'vlibras': vm.vlibras };
    }
});

export default App;
</script>

<style>

</style>