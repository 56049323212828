
import { defineComponent, PropType } from 'vue';
import RespostaQuestaoDTO from '@/DTOs/RespostaQuestaoDTO';
import ImageCard from './ImageCard.vue';

const Imagens = defineComponent({
    props: {
        questoes: {
            type: Array as PropType<RespostaQuestaoDTO[]>,
            required: true
        }
    },
    components: { ImageCard }
});

export default Imagens;
