<template>
    <div class="w-full  px-5 pb-3 first:pt-5">

        <div class="bg-white rounded-lg cursor-pointer shadow-1 border border-transparent transition-colors duration-300"
             @click="$emit('pressed')">

            <!-- Header -->
            <div class="px-4 py-3 flex items-center border-b border-neutral-2">
                <img src="@/assets/icons/camera.svg"/>
                <p class="pl-3 text-lg font-medium flex-1">SE20200000050</p>
            </div>
            
            <!-- Conteúdo -->
            <div class="px-4 py-5 text-opacity-60 text-black">
                Fábio Lima dos Santos Neto
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const ExecucaoCard = defineComponent({
});

export default ExecucaoCard;
</script>

<style>

</style>