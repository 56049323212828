
import { computed, defineComponent, onMounted, reactive } from 'vue';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Canceler } from 'axios';
import Calendar from 'primevue/calendar';
import { titleCase } from '@/utils/stringFunctions';

import AgendamentoCardSkel from '@/components/AgendamentoCardSkel.vue';
import AgendamentoEmptyCard from '@/components/AgendamentoEmptyCard.vue';
import HomeIllustration from '@/components/illustrations/HomeIllustration.vue';
import ExameDetailPage from './ExameDetailPage.vue';
import ExameCard from './ExameCard.vue';
import Loading from '@/components/Loading.vue';

import ExameService from '@/services/ExameService';
import vm from '@/viewModels/MainViewModel';
import UserDTO from '@/DTOs/UserDTO';
import ExameDetailDTO from '@/DTOs/ExameDetailDTO';
import ExameDTO from '@/DTOs/ExameDTO';


interface ExamesState {
    loading: boolean;
    exames: ExameDTO[];
    idExameSelecionado: number;
    loadingDetail: boolean;
    examDetail: ExameDetailDTO | null;
    selectedDate: Date;
    selectedDateTemp: Date;
    calendarOpened: boolean;
}

const ExamesPage = defineComponent({
    components: { AgendamentoCardSkel, AgendamentoEmptyCard, HomeIllustration, ExameDetailPage, ExameCard, Loading, Calendar },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
         
        const state = reactive<ExamesState>({
            loading: false,
            exames: [],
            idExameSelecionado: -1,
            loadingDetail: false,
            examDetail: null,
            selectedDate: new Date(),
            selectedDateTemp: new Date(),
            calendarOpened: false
        });

        const formattedDateTemp = computed(() => {
            return titleCase(format(state.selectedDateTemp, "E', 'dd LLL", { locale: ptBR }));
        });

        const formattedDate = computed(() => {
            return format(state.selectedDate, "dd / MMMM / yyyy", { locale: ptBR });
        });

        const carregarExames = () => {
            state.loading = true;
            const [request] = ExameService.GetExames({
                clientId: user.value.clientId,
                tamanhoPagina: 2000,
                status: "AUDITADA",
                pagina: 1,
                dataProva: format(state.selectedDate, "MM/dd/yyyy")
            });

            request
                .then(resp => state.exames = resp)
                .catch(error => console.log(error))
                .finally(() => state.loading = false);
        };

        const toogleCalendar = () => {
            state.selectedDateTemp = new Date(state.selectedDate);
            state.calendarOpened = !state.calendarOpened;
        };

        const changeDate = () => {
            state.selectedDate = new Date(state.selectedDateTemp);
            state.calendarOpened = !state.calendarOpened;
            carregarExames();
        };

        let getDetailCanceler: Canceler | null = null;
        const loadDetail = (examId: number) => {
            // Cancelar Requisição caso esteja ativa
            getDetailCanceler && getDetailCanceler();
            
            // Setar os valores
            state.loadingDetail = true;
            state.idExameSelecionado = examId;
            
            const [ request, canceler ] = ExameService.GetExamDetail(examId);
            getDetailCanceler = canceler;
            request
                .then(resp => {
                    state.examDetail = resp;
                })
                .catch(error => console.log(error))
                .finally(() => state.loadingDetail = false);
        };

        onMounted(() => carregarExames());

        return { state, loadDetail, formattedDateTemp, formattedDate, toogleCalendar,changeDate };
    }
});

export default ExamesPage;
