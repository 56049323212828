
import { computed, defineComponent, PropType, ref } from 'vue';


import Respostas from './Respostas.vue';
import Video from './Video.vue';
import Imagens from './Imagens.vue';
import ExameDetailDTO from '@/DTOs/ExameDetailDTO';
import { format, parse } from 'date-fns';

const ExameDetailPage = defineComponent({
    components: { Respostas, Video, Imagens },
    props: {
        exame: {
            type: Object as PropType<ExameDetailDTO>,
            required: true
        }
    },
    setup(props) {
        const page = ref<'video' | 'imagens' | 'respostas'>("video");

        const formattedBegin = computed(() => {
            const date = parse(props.exame.dataHoraInicio, "dd/MM/yyyy HH:mm", new Date());
            return format(date, "HH'h'mm");
        });

        const formattedEnd = computed(() => {
            const date = parse(props.exame.dataHoraFim, "dd/MM/yyyy HH:mm", new Date());
            return format(date, "HH'h'mm");
        });

        
        return { page, formattedBegin, formattedEnd };
    }
});

export default ExameDetailPage;
