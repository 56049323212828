import axios from 'axios';
import qs from 'qs';

const http = axios.create({
    timeout: 180000,
    paramsSerializer: params => qs.stringify(params),
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IjYwMGRmNjhjMDI0N2YwNGFmZjViNmY1NCIsInVuaXF1ZV9uYW1lIjoiVlNPRlRfNSIsInJvbGUiOiJBZG1pbiIsIm5iZiI6MTYxMTUyNzgyMCwiZXhwIjoxNjExNTI3ODgwLCJpYXQiOjE2MTE1Mjc4MjB9._YZeKBo1dxD85Lje5IJ8q_RPG7FgMFKmKQs-l5RR37c' 
    }
});

// /easy-procto/agendamento/api/agendamentos/197/status/Executado

export default http;