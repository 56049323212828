
import { defineComponent, ref } from 'vue';
import ArrowButton from '@/components/ArrowButton.vue';

const Respostas = defineComponent({
    components: { ArrowButton },
    setup() {
        const teste = ref(0);
        return { teste };
    }
});

export default Respostas;
