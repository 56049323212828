import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import alert from '@/plugins/alert';
import mediaPermission from '@/plugins/mediaPermission';

import "@/assets/css/main.css";
import '@/assets/css/transitions.css';

import PrimeVue from 'primevue/config';

const app = createApp(App);
app.use(mediaPermission);
app.use(router);
app.use(alert);
app.use(PrimeVue, {
    locale: {
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: "Hoje",
        weekHeader:"Sm",
        firstDayOfWeek:	0,
        dateFormat:	"mm/dd/yy",
        weak: "Semana"
    }
});

app.mount('#app');
