<template>
    <div class="w-full px-5 pb-3 first:pt-5">
        <div class="bg-white rounded-lg shadow-1 p-8 text-center">
            <p class="font-medium">Não há exames agendados nesta data.</p>
            <p class="mb-7">Selecione outra data no calendário.</p>
            <img class="m-auto" src="@/assets/img/calendar.svg"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const AgendamentoEmptyCard = defineComponent({
});

export default AgendamentoEmptyCard;
</script>

<style>

</style>