<template>
    <div class="w-full  px-5 pb-3 first:pt-5">
        <div class="bg-white rounded-lg shadow-1">
            <!-- Header -->
            <div class="px-4 py-3 flex items-center border-b border-neutral-2">
                <div class="skeleton w-6"></div>
                <div class="skeleton w-32 ml-2"></div>
                
            </div>
            <!-- Conteúdo -->
            <div class="px-4 py-5">
                <div class="skeleton w-64"></div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const AgendamentoCardSkel = defineComponent({
});

export default AgendamentoCardSkel;
</script>

<style>

</style>