<template>
    <div class="h-screen pt-14 flex items-stretch">

        <!-- Listagem -->
        <div style="width: 415px" class="bg-neutral-3">
            <div class="w-full h-full overflow-hidden flex flex-col items-stretch">

                <!-- Header -->
                <div class="relative h-18 w-full bg-white border-b border-neutral-1 px-7 flex items-center z-30 flex-shrink-0">
                    <img src="@/assets/icons/calendar.svg"/>
                    <div class="flex-1 pl-7">
                        <p class="text-sm leading-5">Provas agendadas</p>
                        <div class="flex items-center cursor-pointer" @click="toogleCalendar()">
                            <p class="font-bold text-lg leading-5">{{ formattedDate }}</p>
                            <img class="transform transition-transform duration-200 w-3 mx-2" :class="{'rotate-180': state.calendarOpened}"  src="@/assets/icons/arrow-down.svg"/>
                        </div>
                    </div>
                    <!-- <button>
                        <img src="@/assets/icons/search.svg"/>
                    </button> -->
                </div>

                <Transition name="fade" mode="out-in">
                    <div class="bg-white flex-1 flex flex-col items-stretch justify-between px-10 py-6"
                         v-if="state.calendarOpened">
                        <div>
                            <p class="font-medium opacity-60">{{ state.selectedDateTemp.getFullYear() }}</p>
                            <p class="font-medium text-primary text-4xl">{{ formattedDateTemp }}</p>
                        </div>
                        <Calendar v-model="state.selectedDateTemp" :inline="true" />

                        <div class="flex">
                            <button class="btn-hollow flex-1 h-12" @click="toogleCalendar()">Cancelar</button>
                            <div class="w-4"></div>
                            <button class="btn-primary flex-1 h-12" @click="changeDate()">Ok</button>
                        </div>
                    </div>

                    <div class="flex-1 overflow-x-hidden light-scroll" v-else-if="state.loading" key="1">
                        <AgendamentoCardSkel v-for="n in 8" :key="n"/>
                    </div>

                    <div class="flex-1 overflow-x-hidden light-scroll" v-else key="2">
                        <AgendamentoEmptyCard v-if="state.exames.length == 0"/>
                        <ExameCard v-for="exame in state.exames" :key="exame.id" :exame="exame"
                                   :selected="state.idExameSelecionado == exame.agendamentoId"
                                   @selected="loadDetail(exame.agendamentoId)"/>
                    </div>
                </Transition>
            </div>
        </div>

        <!-- Conteúdo -->
        <transition name="fade" mode="out-in">
            <div style="width: calc(100% - 415px)" class="flex flex-col items-center justify-center" v-if="state.loadingDetail" key="1">
                <p class="text-2xl font-bold mb-7">Carregando detalhes da prova</p>
                <Loading color="var(--primary-color)"/>
            </div>

            <ExameDetailPage v-else-if="state.examDetail" :exame="state.examDetail"/>

            <div class="bg-white border-l border-neutral-3 flex flex-col items-center p-20 relative overflow-hidden"
                 key="2"
                 v-else
                 style="width: calc(100% - 415px)">

                <HomeIllustration class="absolute bottom-0 w-full pointer-events-none" style="min-height: 400px;"/>

                <p class="text-4xl font-bold mb-7">Lista de provas realizadas</p>
                <p class="w-80 text-center">Para visualizar os detalhes de um exame, selecione a prova que deseja consultar.</p>
            </div>
        </transition>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive } from 'vue';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Canceler } from 'axios';
import Calendar from 'primevue/calendar';
import { titleCase } from '@/utils/stringFunctions';

import AgendamentoCardSkel from '@/components/AgendamentoCardSkel.vue';
import AgendamentoEmptyCard from '@/components/AgendamentoEmptyCard.vue';
import HomeIllustration from '@/components/illustrations/HomeIllustration.vue';
import ExameDetailPage from './ExameDetailPage.vue';
import ExameCard from './ExameCard.vue';
import Loading from '@/components/Loading.vue';

import ExameService from '@/services/ExameService';
import vm from '@/viewModels/MainViewModel';
import UserDTO from '@/DTOs/UserDTO';
import ExameDetailDTO from '@/DTOs/ExameDetailDTO';
import ExameDTO from '@/DTOs/ExameDTO';


interface ExamesState {
    loading: boolean;
    exames: ExameDTO[];
    idExameSelecionado: number;
    loadingDetail: boolean;
    examDetail: ExameDetailDTO | null;
    selectedDate: Date;
    selectedDateTemp: Date;
    calendarOpened: boolean;
}

const ExamesPage = defineComponent({
    components: { AgendamentoCardSkel, AgendamentoEmptyCard, HomeIllustration, ExameDetailPage, ExameCard, Loading, Calendar },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
         
        const state = reactive<ExamesState>({
            loading: false,
            exames: [],
            idExameSelecionado: -1,
            loadingDetail: false,
            examDetail: null,
            selectedDate: new Date(),
            selectedDateTemp: new Date(),
            calendarOpened: false
        });

        const formattedDateTemp = computed(() => {
            return titleCase(format(state.selectedDateTemp, "E', 'dd LLL", { locale: ptBR }));
        });

        const formattedDate = computed(() => {
            return format(state.selectedDate, "dd / MMMM / yyyy", { locale: ptBR });
        });

        const carregarExames = () => {
            state.loading = true;
            const [request] = ExameService.GetExames({
                clientId: user.value.clientId,
                tamanhoPagina: 2000,
                status: "AUDITADA",
                pagina: 1,
                dataProva: format(state.selectedDate, "MM/dd/yyyy")
            });

            request
                .then(resp => state.exames = resp)
                .catch(error => console.log(error))
                .finally(() => state.loading = false);
        };

        const toogleCalendar = () => {
            state.selectedDateTemp = new Date(state.selectedDate);
            state.calendarOpened = !state.calendarOpened;
        };

        const changeDate = () => {
            state.selectedDate = new Date(state.selectedDateTemp);
            state.calendarOpened = !state.calendarOpened;
            carregarExames();
        };

        let getDetailCanceler: Canceler | null = null;
        const loadDetail = (examId: number) => {
            // Cancelar Requisição caso esteja ativa
            getDetailCanceler && getDetailCanceler();
            
            // Setar os valores
            state.loadingDetail = true;
            state.idExameSelecionado = examId;
            
            const [ request, canceler ] = ExameService.GetExamDetail(examId);
            getDetailCanceler = canceler;
            request
                .then(resp => {
                    state.examDetail = resp;
                })
                .catch(error => console.log(error))
                .finally(() => state.loadingDetail = false);
        };

        onMounted(() => carregarExames());

        return { state, loadDetail, formattedDateTemp, formattedDate, toogleCalendar,changeDate };
    }
});

export default ExamesPage;
</script>

<style>
.p-calendar {
    width: 100%;
}
.p-datepicker-group-container {
    width: 100%;
}
.p-datepicker-month {
    margin-right: 5px;
}
.p-link {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.p-datepicker-prev {
    background-image: url("~@/assets/icons/caret-left.svg")
}
.p-datepicker-next  {
    background-image: url("~@/assets/icons/caret-right.svg")
}
.p-datepicker-calendar th {
    font-size: 0.875rem;
    opacity: .7;
    font-weight: normal;
    height: 50px;
}

.p-datepicker-calendar td span {
    font-size: 0.875rem;
    height: 34px;
    width: 34px;
    outline: none;
    transition: .3s;
    border-radius: 18px;
    color: #363738;
}
.p-datepicker-calendar td span[tabindex="0"] {
    background: #04986D;
    color: white;
    font-weight: 500;
}
.p-datepicker-header {
    margin-bottom: 8px;
}
.p-datepicker-header span {
    font-weight: 500;
    color: #363738;
}
.p-disabled {
    opacity: .4;
}
</style>