
import { defineComponent, PropType, ref } from 'vue';
import { AlertaDTO } from '@/DTOs/AlertaDTO';
import { format } from 'date-fns';
import Dropdown from '@/components/Dropdown.vue';
import Icon from '@/components/Icon.vue';
import AuditoriaDTO from '@/DTOs/AuditoriaDTO';
import vm from '@/viewModels/MainViewModel';
import ExameService from '@/services/ExameService';

const Video = defineComponent({
    components: { Dropdown, Icon },
    props: {
        urlVideo: {
            type: String,
            required: true
        },
        alertas: {
            type: Array as PropType<AlertaDTO[]>,
            required: true
        },
        agendamentoId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const video = ref<HTMLVideoElement | null>(null);
        const alertasOrdenados = [...props.alertas].sort((field1, field2) => {
            if (field1.inicio! < field2.inicio!) {
                return -1;
            }
            if (field1.inicio! > field2.inicio!) {
                return 1;
            }
            // a deve ser igual a b
            return 0;
        });
        const switchOn = ref(false);

        const formatTime = (miliseconds: number) => {
            return format(new Date(0,0,0,0,0,0,miliseconds), "HH:mm:ss");
        };

        const goToVideoPosition = (miliseconds: number) => {
            if(video.value) {
                video.value.currentTime = miliseconds / 1000;
                video.value.play();
            }
        };

        const auditar = (situacao: "AlertaLiberado" | "IndicioFraude" | "AlertaInvalido" | 'Default', alerta: AlertaDTO) => {
            const auditoria: AuditoriaDTO = {
                gravacaoId: props.agendamentoId,
                auditorId: vm.user!.id,
                situacaoAlerta: situacao,
                alertaId: alerta.id,
                tipoAlerta: alerta.tipo
            };

            const oldValue = alerta.situacaoAlerta;
            alerta.situacaoAlerta = situacao;

            const [request] = ExameService.Auditar(auditoria);
            request.catch(() => {
                alerta.situacaoAlerta = oldValue;
            });
        };

        const statusIcon = (situacao: "AlertaLiberado" | "IndicioFraude" | "AlertaInvalido" | 'Default') => {
            switch (situacao) {
                case 'AlertaLiberado':
                    return 'check-circle';
                case 'IndicioFraude':
                    return 'times-circle';
                case 'AlertaInvalido':
                    return 'alert';
                default:
                    return 'cross-eye';
            }
        };

        return { alertasOrdenados, switchOn, formatTime, video, goToVideoPosition, auditar, statusIcon };
    }
});

export default Video;
