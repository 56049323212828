
import { defineComponent, ref } from 'vue';
import Modal from '@/components/Modal.vue';
import Icon from '@/components/Icon.vue';

const MediaPermissionModal = defineComponent({
    components: { Modal, Icon },
    setup() {
        const isOpened = ref(false);
        const cameraLockedModalOpened = ref(false);
        
        const permissionStatus = ref<'none' | 'granted' | 'notGranted'>('none');
        
        const checkPermissions = async () => {
            permissionStatus.value = 'none';

            setTimeout(() => {
                if(permissionStatus.value == 'none') {
                    isOpened.value = true;
                }
            }, 3000);

            const constraints: MediaStreamConstraints = {
                audio: true, 
                video: true
            };

            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                stream.getTracks().forEach(el => {
                    el.stop();
                });
                permissionStatus.value = 'granted';
                isOpened.value = false;
                return true;

            } catch (error) {
                permissionStatus.value = 'notGranted';
                isOpened.value = false;
                cameraLockedModalOpened.value = true;
                return false;
            }
        };

        return { isOpened, cameraLockedModalOpened, checkPermissions, permissionStatus };
    }
});

export default MediaPermissionModal;
